
/* Tooltip background */
.background {
    font-size: initial;
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.6);
    text-align: left;
    z-index: 99999;
}

/* Tooltip container */
.tooltip {
    position: absolute;
    background-color: #B65179;
    width: 260px;
    color: #fff;
    text-align: left;
    padding: 10px;
    border-radius: 6px;
    border: 2px solid #FFD2D2;
    opacity: 0.9;
}

.tooltip p {
    margin: auto;
    padding: inherit;
}

.tooltip li {
    padding: 5px 0 5px 0 !important;
}

/* Point top */
.up {
    position: absolute;
    margin-top: -38px;
    top: 0;
    left: 5px;
    color: #B65179;
}

/* Point bottom */
.down {
    position: absolute;
    margin-top: -38px;
    top: 0;
    left: 5px;
    color: #B65179;
}

/* Point left */
.left {
    position: absolute;
    margin-left: -25px;
    margin-top: 5px;
    top: 0;
    left: 0;
    color: #B65179;
}

/* Point right */
.right {
    position: absolute;
    margin-top: -38px;
    top: 0;
    left: 5px;
    color: #B65179;
}
